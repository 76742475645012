import { FC } from "react"
import { Link } from "react-router-dom"
import "./navBar.scss"
import BigStarIcon from "../icons/BigStarIcon"
import { getSubscriptionPrice } from "../../utils"
import LogoIcon from "../icons/LogoIcon"
import { HostType, SourceType } from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"

interface NavBarProps {}

const NavBar: FC<NavBarProps> = () => {
  const abVersion: "A" | "B" =
    (localStorage.getItem("version") as "A" | "B") ?? "A"

  const { siteName } =
    globalHostConfig[localStorage.getItem("host") as HostType]

  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  return (
    <>
      <nav id="nav-bar" className={specificClassName}>
        <div className="nav-content">
          <div className="logo-nav">
            <Link to={globalConfig[source].redirectUrl || "/"}>
              <LogoIcon
                style={{
                  width: "35px",
                  height: "35px",
                }}
              />
              <h3 className={`title light mobile-view`}>{siteName}</h3>
              <h3 className={`title light desktop-view`}>
                {siteName} : 3 jours gratuits puis {getSubscriptionPrice()}
                €/mois
              </h3>
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar
