import { IHostConfig } from "../../config"
import { getSubscriptionPrice } from "../../utils"

export type QuestionType = {
  index: number
  question: string
  answer: string
}

export const getQuestions = (hostData: IHostConfig): QuestionType[] => [
  {
    index: 0,
    question: `Qu’est-ce que l’abonnement à l’assistant personnel ${hostData.siteName} ?`,
    answer: `
            <p>
                L'abonnement à <a href="${hostData.webSite}" traget="_blank">${
      hostData.webSiteDomain
    }</a> vous donne accès à notre assistant personnel pour simplifier la gestion de vos réservations, de vos rendez-vous et bien plus encore. Pour seulement ${getSubscriptionPrice()} euros/mois après une période d'essai gratuite de 3 jours, profitez d’une gestion sur mesure de vos services préférés. Si, pour une raison quelconque, vous n'êtes pas pleinement satisfait(e) de notre service, vous pouvez annuler votre abonnement à tout moment et obtenir un remboursement intégral.
            </p>
        `,
  },
  {
    index: 1,
    question: "Y a-t-il une période d’essai gratuite ?",
    answer: `Oui ! Nous proposons une période d'essai gratuite de 3 jours. Pendant cette période, vous pourrez explorer toutes les fonctionnalités de l'assistant et décider si cela répond à vos besoins. À la fin de cette période, sans annulation de votre part, vous serez abonné pour ${getSubscriptionPrice()}euros/mois, annulable à tout moment.`,
  },
  {
    index: 2,
    question: "Quels sont les services inclus dans mon abonnement ?",
    answer: `
        <p>
            Nous vous offrons une gamme complète de services et avantages pour vous simplifier la vie :
        </p>
        <ul>
            <li>L’expertise de notre équipe pour des conseils personnalisés dans vos itinéraires de voyage.</li>
            <li>Une assistance 7/7 et disponible pour répondre à toutes vos questions et vous aider en cas de besoin.</li>
            <li>Un service simple et rapide.</li>
            <li>Du contact humain car nous accordons une attention particulière à votre satisfaction.</li>
            <li>Des prix transparents et fiables sans frais cachés, afin que vous puissiez réserver en toute confiance.</li>
        </ul>
    `,
  },
  {
    index: 3,
    question: "Puis-je annuler mon abonnement à tout moment ?",
    answer: `
        <p>
        Oui, vous pouvez annuler votre abonnement à tout moment. En cliquant sur “gérer mon abonnement” de votre espace ou depuis ce lien : <a href="${hostData.webSite}/abonnement" target="_blank">${hostData.webSite}/abonnement</a>.<br/>Notre équipe est réactive et annulera votre abonnement dans les plus brefs délais.
        </p>
    `,
  },
  {
    index: 4,
    question: "Comment annuler mon abonnement ?",
    answer: `
        <p>Nous mettons tout en oeuvre pour que votre demande de désabonnement soit traitée au plus vite.</p>
        <p>Pour vous désabonner, vous pouvez :</p>
        <ul>
        <li>Joindre notre service client au numéro suivant : <a href="tel:+33${
          hostData.faqPhone
            ? hostData.faqPhone.slice(1)
            : hostData.contactPhone.slice(1)
        }">${hostData.faqPhone ?? hostData.contactPhone}</a></li>
        <li>Nous écrire par email à : <a href="mailto:${
          hostData.contactEmail
        }">${hostData.contactEmail}</a></li>
        <li>Nous écrire sur le live chat disponible en bas à gauche de votre écran sur <a href="${
          hostData.webSite
        }/" target="_blank">${hostData.webSite}</a></li>
        </ul>
        <p>Pour nous contacter, retrouvez toutes ces options sur la page contact du sire OrgaCall : <a href="${
          hostData.webSite
        }/contact/" target="_blank">${hostData.webSite}/contact</a></p>
    `,
  },
]
