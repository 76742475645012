import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  HostTypeEnum,
  SourceType,
  SourceTypeEnum,
} from "../../context/comparator/types"
import "./redirect.scss"
import { globalHostConfig } from "../../config"

const Redirect = () => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const randomRedirect = () => {
    let urls: string[] = []
    if (
      window.location.href.includes(":3001") ||
      window.location.href.includes("local")
    ) {
      urls = [
        "http://local-orga-call.payment-secure-booking:3001",
        "http://local-reservapp.payment-secure-booking:3001",
        "http://local-reservup.payment-secure-booking:3001",
        // "http://local-reserv4me.payment-secure-booking:3001",
        // "http://local-servimate.payment-secure-booking:3001",
        // "http://local-taxi-reserv.payment-secure-booking:3001",
      ]
    } else if (window.location.hostname.includes("staging")) {
      urls = [
        "https://staging-orga-call.payment-secure-booking.com",
        "https://staging-reservapp.payment-secure-booking.com",
        "https://staging-reservup.payment-secure-booking.com",
        // "https://staging-reserv4me.payment-secure-booking.com",
        // "https://staging-servimate.payment-secure-booking.com",
        // "https://staging-taxi-reserv.payment-secure-booking.com",
      ]
    } else {
      urls = [
        "https://orga-call.payment-secure-booking.com",
        "https://reservapp.payment-secure-booking.com",
        "https://reservup.payment-secure-booking.com",
        // "https://reserv4me.payment-secure-booking.com",
        //   "https://servimate.payment-secure-booking.com",
        //   "https://taxi-reserv.payment-secure-booking.com",
      ]
    }
    let randomIndex = Math.floor(Math.random() * urls.length)
    const url = urls[randomIndex] + "/pay" + window.location.search

    setTimeout(() => {
      window.location.href = url
    }, 100)
  }

  const redirectBySource = () => {
    const sourceWebsite = localStorage.getItem("source") as SourceType
    let url: string = ""
    switch (sourceWebsite) {
      case SourceTypeEnum.Train:
      case SourceTypeEnum.TrainEasy:
        // case SourceTypeEnum.TrainGo:
        if (
          window.location.href.includes(":3001") ||
          window.location.href.includes("local")
        ) {
          url =
            "http://local-booking.reserv4me:3001/pay" + window.location.search
        } else if (window.location.hostname.includes("staging")) {
          url =
            "https://staging-feature.reserv4me.net/pay" + window.location.search
        } else {
          url = "https://booking.reservapp.net/pay" + window.location.search
        }
        break

      case SourceTypeEnum.Bus:
      case SourceTypeEnum.BusEasy:
        // case SourceTypeEnum.BusGo:
        if (window.location.href.includes(":3001")) {
          url =
            "http://local-booking.reserv4me:3001/pay" + window.location.search
        } else if (window.location.hostname.includes("staging")) {
          url =
            "https://staging-feature.reserv4me.net/pay" + window.location.search
        } else {
          url = "https://booking.servimate.net/pay" + window.location.search
        }
        break

      case SourceTypeEnum.Avion:
      case SourceTypeEnum.AvionEasy:
        // case SourceTypeEnum.AvionGo:
        if (window.location.href.includes(":3001")) {
          url =
            "http://local-booking.reserv4me:3001/pay" + window.location.search
        } else if (window.location.hostname.includes("staging")) {
          url =
            "https://staging-feature.reserv4me.net/pay" + window.location.search
        } else {
          url = "https://booking.reservup.net/pay" + window.location.search
        }
        break
    }

    const host = url?.includes("orga-call")
      ? "orga-call"
      : url?.includes("reservapp")
      ? "reservapp"
      : url?.includes("reservup")
      ? "reservup"
      : url?.includes("servimate")
      ? "servimate"
      : url?.includes("reserv4me")
      ? "reserv4me"
      : // : url?.includes("taxi-reserv")
        // ? "taxi-reserv"
        "reservapp"

    localStorage.setItem("host", host)

    setTimeout(() => {
      if (
        globalHostConfig[host]?.stripeKey &&
        globalHostConfig[host]?.stripeKey !== ""
      ) {
        window.location.href = url
      }
    }, 100)
  }

  useEffect(() => {
    // const hostTypes = Object.values(HostTypeEnum)
    // const hostType = hostTypes.find((hostType) =>
    //   window.location.hostname.includes(hostType)
    // )

    // if (!hostType) {
    // randomRedirect()
    redirectBySource()
    // } else if (hostType && window.location.pathname === "/") {
    //   navigate({
    //     pathname: "/pay",
    //     search: window.location.search,
    //   })
    // }
  }, [])

  return (
    <div id="spinner-container">
      <div id="wave">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  )
}

export default Redirect
