import { HostType, SourceType } from "./context/comparator/types"

export interface IConfig {
  name: string
  slack: {
    confirmUrl: string
    callMeBackUrl: string
  }
  redirectUrl: string
  "meta-description": string
  specificClassName: string
  subscriptionPrice: string
}

type IGlobalConfig = {
  [key in SourceType]: IConfig
}

export const globalConfig: IGlobalConfig = {
  train: {
    name: "Train Malin",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_TRAIN as string,
    "meta-description": "Comparez vos trajets en train",
    specificClassName: "",
    subscriptionPrice: process.env.REACT_APP_PRICE_TRAIN_SUBSCRIPTION as string,
  },
  bus: {
    name: "Bus Malin",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_BUS as string,
    "meta-description": "Comparez vos trajets en bus",
    specificClassName: "",
    subscriptionPrice: process.env.REACT_APP_PRICE_BUS_SUBSCRIPTION as string,
  },
  avion: {
    name: "Avion Malin",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_AVION as string,
    "meta-description": "Comparez vos trajets en avion",
    specificClassName: "",
    subscriptionPrice: process.env.REACT_APP_PRICE_AVION_SUBSCRIPTION as string,
  },
  "train-easy": {
    name: "Train Easy",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_TRAIN_EASY as string,
    "meta-description": "Comparez vos trajets en train",
    specificClassName: "style--easy",
    subscriptionPrice: process.env.REACT_APP_PRICE_TRAIN_SUBSCRIPTION as string,
  },
  "bus-easy": {
    name: "Bus Easy",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_BUS_EASY as string,
    "meta-description": "Comparez vos trajets en bus",
    specificClassName: "style--easy",
    subscriptionPrice: process.env.REACT_APP_PRICE_BUS_SUBSCRIPTION as string,
  },
  "avion-easy": {
    name: "Avion Easy",
    slack: {
      confirmUrl: process.env.REACT_APP_SLACK_CONFIRM as string,
      callMeBackUrl: process.env.REACT_APP_SLACK_CALL_ME as string,
    },
    redirectUrl: process.env.REACT_APP_REDIRECT_URL_AVION_EASY as string,
    "meta-description": "Comparez vos trajets en avion",
    specificClassName: "style--easy",
    subscriptionPrice: process.env.REACT_APP_PRICE_AVION_SUBSCRIPTION as string,
  },
}

export interface IHostConfig {
  siteName: string
  companyName: string
  companyAddress: string
  companyRegistration: string
  director: string
  companyEmail?: string
  contactEmail: string
  contactPhone: string
  faqPhone?: string
  hostLocation: string
  stripeKey: string
  webSite: string
  webSiteLegales: string
  webSiteDomain: string
}

type IGlobalHostConfig = {
  [key in HostType]: IHostConfig
}

export const globalHostConfig: IGlobalHostConfig = {
  "orga-call": {
    siteName: "OrgaCall",
    companyName: "LEAF LIGHT LIMITED",
    companyAddress:
      "Suite 1e The Granary, Bridge Street, Ashford, TN25 5ED, United Kingdom",
    companyRegistration: "13943058",
    director: "Chloe Ellen MacKay",
    contactEmail: "contact@orga-call.com",
    contactPhone: "01 89 71 39 71",
    hostLocation: "France",
    stripeKey: process.env.REACT_APP_ORGACALL_STRIPE_PUBLIC_KEY as string,
    webSite: "https://orga-call.com",
    webSiteLegales: "www.orga-call.com",
    webSiteDomain: "orga-call.com",
  },
  reservapp: {
    siteName: "ReservApp",
    companyName: "Creative Sun Limited",
    companyAddress:
      "Suite 2, Office 2 The Coach House Little Bardfield Hall, Little Bardfield, Braintree, Essex, England, CM7 4TT",
    companyRegistration: "14120293",
    director: "Matthew Paul Dunn",
    contactEmail: "contact@reservapp.net",
    contactPhone: "01 89 71 00 05",
    hostLocation: "États-Unis",
    stripeKey: process.env.REACT_APP_RESERVAPP_STRIPE_PUBLIC_KEY as string,
    webSite: "https://reservapp.net",
    webSiteLegales: "www.reservapp.net",
    webSiteDomain: "reservapp.net",
  },
  reservup: {
    siteName: "ReservUp",
    companyName: "Rolling Bay Limited",
    companyAddress:
      "Office 3, Suite A, 13 Reeves Way, South Woodham Ferrers, UK, CM3 5XF",
    companyRegistration: "13943053",
    director: "Dawn Rosemary Wootten",
    contactEmail: "contact@reservup.net",
    contactPhone: "01 89 71 39 98",
    hostLocation: "France",
    stripeKey: process.env.REACT_APP_RESERVUP_STRIPE_PUBLIC_KEY as string,
    webSite: "https://reservup.net",
    webSiteLegales: "www.reservup.net",
    webSiteDomain: "reservup.net",
  },
  servimate: {
    siteName: "ServiMate",
    companyName: "Raybell Limited",
    companyAddress: "Office B, 11 Old Ladies Court, Battle, UK, TN33 0AH",
    companyRegistration: "13971672",
    director: "Maria Jean Aldridge",
    contactEmail: "contact@servimate.net",
    contactPhone: "01 89 71 00 03",
    hostLocation: "États-Unis",
    stripeKey: process.env.REACT_APP_SERVIMATE_STRIPE_PUBLIC_KEY as string,
    webSite: "https://servimate.net",
    webSiteLegales: "www.servimate.net",
    webSiteDomain: "servimate.net",
  },
  reserv4me: {
    siteName: "Reserv4Me",
    companyName: "Locked Media Limited",
    companyAddress: "23 Heol Ffion, Swansea, SA4 4PN, UK",
    companyRegistration: "14031690",
    companyEmail: "contact@locked-media.com",
    director: "Adam Stephen Devey",
    contactEmail: "contact@reserv4me.net",
    contactPhone: "01 89 71 39 96",
    faqPhone: "01 89 71 40 97",
    hostLocation: "France",
    stripeKey: process.env.REACT_APP_RESERV4ME_STRIPE_PUBLIC_KEY as string,
    webSite: "https://reserv4me.net",
    webSiteLegales: "www.reserv4me.net",
    webSiteDomain: "reserv4me.net",
  },

  // "taxi-reserv": {
  //   siteName: "Taxi-Reserv",
  //   companyName: "Major Demand Limited",
  //   companyAddress: "Flat 3 9 Summerleaze Crescent, Bude, England, EX23 8HH",
  //   companyRegistration: "14120264",
  //   director: "Matthew Paul Sykes",
  //   contactEmail: "contact@taxi-reserv.com",
  //   contactPhone: "01 89 71 00 01",
  //   hostLocation: "États-Unis",
  //   stripeKey: process.env.REACT_APP_TAXIRESERV_STRIPE_PUBLIC_KEY as string,
  //   webSite: "https://taxi-reserv.com",
  //   webSiteLegales: "www.taxi-reserv.com",
  //   webSiteDomain: "taxi-reserv.com",
  // },
}
