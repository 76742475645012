import React, { useEffect } from "react"

import "./faq.scss"
import Question from "./Question"
import { QuestionType, getQuestions } from "./data"
import { HostType, SourceType } from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"
const Faq = () => {
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const [currentActive, setCurrentActive] = React.useState<number | null>(null)

  const [questions, setQuestions] = React.useState<QuestionType[] | []>([])

  const globalHost = globalHostConfig[localStorage.getItem("host") as HostType]

  const updateActive = (index: number) => {
    if (currentActive === index) {
      setCurrentActive(null)
    } else {
      setCurrentActive(index)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("source")) {
      console.log("test", localStorage.getItem("source"))
      setQuestions(getQuestions(globalHost))
    }
  }, [localStorage.getItem("source")])
  return (
    <section className={`faq-section ${specificClassName}`}>
      <div className="faq-wrapper">
        <h3>Des réponses à vos questions</h3>

        <div className="question-wrapper">
          {questions.map((question) => (
            <Question
              key={question.index}
              index={question.index}
              currentActive={currentActive}
              updateActive={updateActive}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Faq
