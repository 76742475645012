import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import TagManager from "react-gtm-module"
import React from "react"
import { HostType } from "./context/comparator/types"
import { globalHostConfig } from "./config"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const tagManagerArgs = {
  gtmId: "GTM-KLBJ7ZT",
}

TagManager.initialize(tagManagerArgs)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
