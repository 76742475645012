import { Link } from "react-router-dom"
import { Lock } from "react-feather"
import "./footer.scss"
import { HostType, SourceType } from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"

const Footer = () => {
  const source = localStorage.getItem("source") as SourceType

  const { siteName } =
    globalHostConfig[localStorage.getItem("host") as HostType]
  const specificClassName = globalConfig[source].specificClassName
  return (
    <footer className={`footer ${specificClassName}`} id="footer">
      <div className="footer-container">
        <div className="footer-top">
          <Link to="/pay" className="logo">
            <h4 className="light w700">{siteName}</h4>
          </Link>
          <div className="legales">
            <Link to="/privacy-statement" target="_blank" className="underline">
              <p className="light">Charte de confidentialité</p>
            </Link>
            <Link
              to="/terms-of-use-&-legal-notices"
              target="_blank"
              className="underline"
            >
              <p className="light">Conditions générales de vente</p>
            </Link>
          </div>
          <div className="security">
            <h4 className="light desk-only">Paiement sécurisé par</h4>
            <Lock size={16} color="#fff" />
            <img src="/images/Logos CB.png" alt="logo-CB" />
          </div>
        </div>
        <div className="legales-mobile">
          <Link to="/privacy-statement" target="_blank" className="underline">
            <p className="light">Charte de confidentialité</p>
          </Link>
          <Link
            to="/terms-of-use-&-legal-notices"
            target="_blank"
            className="underline"
          >
            <p className="light">Conditions générales de vente</p>
          </Link>
        </div>
        <div className="info">
          <small className="light">
            La conciergerie {siteName} n'est pas une agence de voyage et n’agit
            qu’en qualité d’intermédiaire dans la vente de prestations de
            transport, d’hébergement ou de tout autre type de prestations. La
            conciergerie {siteName} peut dans certains cas avancer au client les
            frais d'achat auprès des partenaires qui délivreront le service
            final mais la responsabilité contractuelle de {siteName} ne saurait
            être engagée pour une faute résultant du contrat de transport,
            d’hébergement ou de tout autre type de prestations.{" "}
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
