import "./App.scss"
import { useEffect, useState } from "react"
import {
  PaymentContext,
  PaymentState,
  initialPaymentState,
} from "./context/paymentContext"
import Router from "./Router"
import { initAmplitude } from "./services/amplitude"
import { GlobalContext } from "./context/globalContext"
import { setCssEasy } from "./services/css"
import {
  ComparatorState,
  HostType,
  SourceType,
} from "./context/comparator/types"
import { globalConfig, globalHostConfig } from "./config"
import { ComparatorContext } from "./context/comparator/context"

function App() {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [comparatorState, setComparatorState] =
    useState<ComparatorState | null>(null)
  const [paymentState, setPaymentState] =
    useState<PaymentState>(initialPaymentState)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1024)

  const initializeApp = () => {
    const urlParams = new URLSearchParams(window.location.search)

    const stateParam = urlParams.get("state")
    const stateJson = stateParam ? JSON.parse(stateParam) : null

    // Initialize the state with the parameters from the URL
    const initialisedComparatorState: ComparatorState = {
      source: stateJson?.source ?? localStorage.getItem("source"),
      source_url: stateJson?.source_url ?? localStorage.getItem("source_url"),
      from:
        typeof stateJson?.from === "string"
          ? { name: stateJson.from }
          : stateJson?.from,
      to:
        typeof stateJson?.to === "string"
          ? { name: stateJson.to }
          : stateJson?.to,
      departureDate: stateJson?.departureDate,
      returnDate: stateJson?.returnDate,
      passengers: stateJson?.passengers,
      price: stateJson?.price,
    }

    const description = document.querySelector(
      "meta[name='description']"
    ) as HTMLMetaElement

    const host = window.location.hostname.includes("orga-call")
      ? "orga-call"
      : window.location.hostname.includes("reservapp")
      ? "reservapp"
      : window.location.hostname.includes("reservup")
      ? "reservup"
      : window.location.hostname.includes("servimate")
      ? "servimate"
      : window.location.hostname.includes("reserv4me")
      ? "reserv4me"
      : // : window.location.hostname.includes("taxi-reserv")
        // ? "taxi-reserv"
        "reservapp"

    localStorage.setItem("host", host)
    document.title = globalHostConfig[host].siteName

    const icon = document.querySelector("link[rel='icon']") as HTMLLinkElement
    const appleTouchIcon = document.querySelector(
      "link[rel='apple-touch-icon']"
    ) as HTMLLinkElement
    icon.href = `images/${host}.svg`
    appleTouchIcon.href = `images/${host}.svg`

    if (initialisedComparatorState?.source) {
      const source = initialisedComparatorState?.source as SourceType

      initAmplitude(source)
      localStorage.setItem("source", source)
      localStorage.setItem("source_url", initialisedComparatorState.source_url)
      description.content =
        globalConfig[source as keyof typeof globalConfig]["meta-description"]

      setCssEasy(initialisedComparatorState.source, host)

      setComparatorState(initialisedComparatorState)
      setLoaded(true)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    initializeApp()

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      {loaded && (
        <div className="App">
          <GlobalContext.Provider
            value={{
              isMobile: isMobile,
            }}
          >
            <ComparatorContext.Provider
              value={{ comparatorState, setComparatorState }}
            >
              <PaymentContext.Provider
                value={{ paymentState, setPaymentState }}
              >
                <Router />
              </PaymentContext.Provider>
            </ComparatorContext.Provider>
          </GlobalContext.Provider>
        </div>
      )}
    </>
  )
}

export default App
