import { useState } from "react"
import "./howTabs.scss"
import { HostType } from "../../context/comparator/types"
import { globalHostConfig } from "../../config"

const HowTabs = () => {
  const [activeTab, setActiveTab] = useState("appel")
  const { siteName } =
    globalHostConfig[localStorage.getItem("host") as HostType]

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div id="how-tabs-container">
      <div className="tabs">
        <div
          className={`tab ${activeTab === "appel" ? "active" : ""}`}
          onClick={() => handleTabClick("appel")}
        >
          <p className="w600 center">Appel</p>
        </div>
        <div
          className={`tab ${activeTab === "message" ? "active" : ""}`}
          onClick={() => handleTabClick("message")}
        >
          <p className="w600 center">Message</p>
        </div>
        <div
          className={`tab ${activeTab === "whatsapp" ? "active" : ""}`}
          onClick={() => handleTabClick("whatsapp")}
        >
          <p className="w600 center">WhatsApp</p>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === "appel" && (
          <div>
            <p className="dark center">
              Echangez directement avec votre assistant au téléphone sur votre
              ligne dédiée.
            </p>
            <br />
            <p className="dark center">
              Le contact humain et l’écoute sont des valeurs fondamentales chez{" "}
              {siteName} !
            </p>
          </div>
        )}
        {activeTab === "message" && (
          <div>
            <p className="dark center">
              Echangez avec votre assistant par message pour tous vos besoins.
            </p>
            <br />
            <p className="dark center">Un SMS c’est simple et rapide !</p>
          </div>
        )}
        {activeTab === "whatsapp" && (
          <div>
            <p className="dark center">
              Vous préférez utiliser WhatsApp ? Avec {siteName} c’est possible !
            </p>
            <br />
            <p className="dark center">
              Enregistrez votre assistant personnel sur WhatsApp et gérez toutes
              vos réservations via l’application.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default HowTabs
