import { HostType, SourceType } from "../context/comparator/types"

export interface GetSecretPayload {
  source: SourceType | ""
  source_url?: string
  customer: {
    phone?: string
    firstname?: string
    lastname?: string
    email?: string
    cashback?: true
  }
  trip_infos: {
    roundtrip: boolean
    price?: number
    origin?: string
    destination?: string
    departure_time?: string
    roundtrip_time?: string
    nb_ticket?: number
  }
}

export const stripeService = {
  getSecret: (params: GetSecretPayload) => {
    const source = localStorage.getItem("source") as SourceType
    const vertical = source.split("-")[0]
    const host = localStorage.getItem("host") as HostType
    const isReserv4Me = host === "reserv4me"
    params.source = vertical as SourceType

    params.source_url = localStorage.getItem("source_url") as string

    return fetch(`${process.env.REACT_APP_API_URL}/setupintent-oc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...params,
        ...(isReserv4Me ? { isReserv4Me } : {}),
      }),
    })
  },
}
