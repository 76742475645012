import "./payment.scss"
import { Award } from "react-feather"
import StripeForm from "../form/StripeForm"
import HowTabs from "../subComponents/HowTabs"
import { useContext, useEffect } from "react"
import {
  getAmplitudeInstance,
  sendAmplitudeTrack,
  setAmplitudeDeviceId,
  setAmplitudeUserId,
} from "../../services/amplitude"
import { GlobalContext } from "../../context/globalContext"
import Faq from "../../section/Faq/Faq"
import Iframe from "react-iframe"
import { getSubscriptionPrice } from "../../utils"
import CrownIcon from "../icons/CrownIcon"
import Skills from "../subComponents/Skills"
import Services from "../subComponents/Services"
import { HostType, SourceType } from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"
import { getColor } from "../../services/css"

const Payment = () => {
  const host = localStorage.getItem("host") as HostType

  const abVersion: "A" | "B" =
    (localStorage.getItem("version") as "A" | "B") ?? "A"

  const { siteName } =
    globalHostConfig[localStorage.getItem("host") as HostType]

  const { isMobile } = useContext(GlobalContext)

  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const urlIframeVisite =
    globalConfig[source].redirectUrl + "/iframeVisite" + window.location.search

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)

    const amplitudeUserId = currentSearchParams.get("auid")
    const amplitudeDeviceId = currentSearchParams.get("adid")

    if (amplitudeUserId) {
      setAmplitudeUserId(amplitudeUserId)
    }
    if (amplitudeDeviceId) {
      setAmplitudeDeviceId(amplitudeDeviceId)
    }

    setTimeout(() => {
      const { deviceId, userId } = getAmplitudeInstance()

      if (deviceId || userId) {
        sendAmplitudeTrack("View - Payment page")
      }
    }, 1000)
  }, [])

  return (
    <div id="payment-page" className={specificClassName}>
      <section className="form-info">
        <div className={`info-container`}>
          <div className="max-width info-content">
            <div className="mobile-view">
              <p
                className="small light"
                style={{
                  lineHeight: "18px",
                  marginBottom: "2px",
                  marginTop: "0px",
                }}
              >
                Conciergerie {}: 3 jours gratuits
                <br />
                puis {getSubscriptionPrice()} eur par mois. Annulable à tout
                moment.
              </p>
              <small className="light justify">
                En devenant membre de la conciergerie vous bénéficiez d’une
                assistance 7 jours sur 7 par téléphone, email et WhatsApp pour
                vous accompagner dans toutes vos réservations ainsi que la
                planification de vos voyages. Profitez des conseils de nos
                experts et voyagez en toute tranquillité ! <br />
                <strong>Réservation du billet à la prochaine étape.</strong>
              </small>
            </div>
            <div className="desktop-view">
              <h3 className="light" style={{ marginBottom: "25px" }}>
                Organisez votre voyage avec {siteName}
              </h3>
              <small className="light justify">
                A cette étape, vous vous inscrivez aux services de la
                conciergerie {siteName}.{" "}
                <strong>
                  La réservation du billet se fait à l’étape suivante sur un
                  site de réservation.
                </strong>
                <br />
                <br />
                En devenant membre de la conciergerie, vous bénéficiez d’une
                assistance 7 jours sur 7 pour vous accompagner dans toutes vos
                réservations ainsi que la planification de vos voyages. Profitez
                des conseils de nos experts et voyagez en toute tranquillité !
                <br />
                <br />
                <strong>
                  L’accès à mon assistant personnel {siteName} s’active à
                  l’enregistrement de mes coordonnées. Profitez de la période
                  d’essai de 3 jours gratuits puis abonnement de{" "}
                  {getSubscriptionPrice()} euros par mois annulable à tout
                  moment. Garantie satisfait ou remboursé.
                </strong>
              </small>
            </div>

            <div className="members-benefits desk-only">
              <div
                className="name"
                style={{
                  marginTop: "70px",
                }}
              >
                <CrownIcon color={getColor(source, host, "icon2")} />{" "}
                <h3 className={"light"} style={{ marginBottom: 0 }}>
                  Avantages Membre
                </h3>
              </div>
              <Skills />
            </div>
          </div>
        </div>
        <div
          className="form-container"
          style={{
            marginTop: "0",
          }}
        >
          <h3 className="red-header mobile-view">Validez vos coordonnées</h3>
          <StripeForm />
        </div>
      </section>

      <section className="content">
        <div className="how">
          <div className="left">
            <div className="header">
              <h3 className="dark">
                <span className="blue">Comment</span> ça marche ?
              </h3>
              <p className="dark center">
                Votre assistant personnel reçoit et traite immédiatement toutes
                vos demandes
                <span className="desk-only">
                  &nbsp; 7 jours sur 7 de 8h à 22h.
                </span>
                <span className="mobile-only w600">
                  &nbsp; 7 jours sur 7 de 8h à 22h.
                </span>
              </p>
              <img
                className="mobile-only"
                src="/images/phone-goresa 1.png"
                alt="phone"
              />

              <HowTabs />
            </div>
          </div>
          <div className="right desk-only">
            <img
              className="desk-only"
              src="/images/phone-goresa 1.png"
              alt="phone"
            />
          </div>
        </div>
      </section>

      <section className="services">
        <div className="max-width">
          <h3 className="dark title">
            Un large choix de <span className="blue">services</span>
          </h3>
          <p className="dark subtitle">
            Pour seulement: 3 jours gratuits puis {getSubscriptionPrice()} eur
            par mois. Annulable à tout moment.
          </p>
          <div className="content">
            <div className="left">
              <img
                className="desk-only"
                src="/images/services.png"
                alt="smile"
              />
            </div>
            <div className="right">
              <Services />
            </div>
          </div>
        </div>
      </section>

      <section className="offer">
        <div className="card light">
          <div className="icon rounded filled light-blue s80">
            <Award size={44} color={getColor(source, host, "icon")} />
          </div>
          <h3 className="dark center title">Profitez de l’offre</h3>
          <p className="dark center">
            {siteName} vous offre une{" "}
            <strong>période d'essai gratuite pendant 3 jours !</strong>
            <br />
            Profitez en pour découvrir le plaisir d’avoir un assistant personnel
            à votre disposition 7 jours sur 7 de 8h à 22h.
            <br />
            Après cette période, vous pouvez prolonger votre abonnement{" "}
            <strong>pour {getSubscriptionPrice()} euros par mois.</strong>
          </p>
          <p className="dark center w600">
            Offre annulable à tout moment et garantie satisfait ou remboursée !
          </p>

          <button
            className="btn red"
            onClick={() => {
              document
                .querySelector(".form-container")
                ?.scrollIntoView({ behavior: "smooth" })
            }}
          >
            <p className="light w600">S’abonner</p>
          </button>
        </div>
      </section>

      {localStorage.getItem("source") && <Faq />}
      <div style={{ zIndex: -1, visibility: "hidden" }} id="iFrameGtm">
        <Iframe url={urlIframeVisite ?? ""} width="0" height="0" />
      </div>
    </div>
  )
}

export default Payment
