import { ArrowLeft } from "react-feather"
import "./legales.scss"
import { Link } from "react-router-dom"
import { HostType, SourceType } from "../../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../../config"

const PrivacyStatement = () => {
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const { siteName, companyName, companyAddress, webSiteLegales } =
    globalHostConfig[localStorage.getItem("host") as HostType]

  return (
    <div className={`container ${specificClassName}`}>
      <div className="header">
        <Link to="/pay" className="btn red">
          <ArrowLeft size={36} />
        </Link>
        <h1>
          <strong>Charte de confidentialité</strong>
        </h1>
      </div>
      <p className="title">
        <strong>Identification</strong>
      </p>
      <p>
        Le site internet {siteName} est la propriété de {companyName}, dont le
        siège social est situé {companyAddress}.
      </p>
      <p className="title">
        <strong>Préambule</strong>
      </p>
      <p>
        {companyName}, via son site internet {siteName}, est amené à réaliser
        des opérations de traitement des données personnelles collectées auprès
        des utilisateurs dudit site.
      </p>
      <p>
        {companyName} s’engage à respecter la vie privée et la protection des
        données personnelles des utilisateurs du service {siteName}.
      </p>
      <p className="title">
        <strong>Objet</strong>
      </p>
      <p>
        L’objectif est d’informer les utilisateurs sur les méthodes employées
        pour le traitement, l’utilisation, l’archivage et les différents droits
        inhérents aux données personnelles collectées. Des mesures et
        dispositions sont prévues par la loi 78-17 du 6 janvier 1978 sur la
        législation du secteur de “L’Informatique et les libertés”.
      </p>
      <p className="title">
        <strong>
          Nature et destinations des opérations de traitement des données
          personnelles collectées
        </strong>
      </p>
      <p>
        La collecte ou le traitement des données personnelles de l’utilisateur
        sont effectués à plusieurs étapes du parcours de l’utilisateur sur le
        site {siteName}, notamment lors de l’inscription et de l’ouverture du
        compte utilisateur, pendant les actions réalisées en ligne sur le site
        et pour toute opération de paiement effectuée sur le site{" "}
        {webSiteLegales}.
      </p>
      <p>
        Les opérations de traitement et les méthodes de collecte sont réalisées
        directement auprès de l’utilisateur lors de l’utilisation du site{" "}
        {siteName}.
      </p>
      <p>
        Lors de l’utilisation du service {siteName}, l’utilisateur prend acte et
        est conscient que des données personnelles peuvent être prélevées.
      </p>
      <p>Quels sont les types de données pouvant être collectées ?</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          les données d’identification et facilitant la facturation : nom,
          prénom, adresse, e-mail, etc.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          les données bancaires facilitant le paiement,
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          les données d’exploitation commerciale : conversation SAV,
          commentaires…
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          les données de facturation et d’état du règlement
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>les données de connexion</li>
      </ul>
      <p>
        Une astérisque est présente pour indiquer les données obligatoirement
        collectées, qui seront potentiellement utilisées et traitées. En
        l’absence de ce symbole, la collecte ne sera pas forcément réalisée.
      </p>
      <p className="title">
        <strong>Durée et archivage des données personnelles</strong>
      </p>
      <p>
        Les données personnelles collectées auprès des utilisateurs par{" "}
        {siteName} ont pour but de :
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          réaliser un suivi de la clientèle et de l’état de satisfaction des
          utilisateurs
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          gérer le paiement et le suivi des commandes
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          opérer la gestion des comptes clients liés aux utilisateurs
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          proposer un service adapté individuellement le cas échéant et
          améliorer la proposition de service
        </li>
      </ul>
      <p>
        La destination de ces données personnelles pourra être uniquement ou
        simultanément le service client de {siteName}, ainsi que certains
        sous-traitants et partenaires commerciaux.
      </p>
      <p>
        Lors d’une investigation judiciaire agréée par une réquisition policière
        ou administrative, {siteName} pourra autoriser la consultation de ces
        données et, le cas échéant, les communiquer aux fins d’être produites.
      </p>
      <p>
        La politique de durée de conservation des données collectées et
        archivées est exprimée proportionnellement à la durée de souscription du
        service.
      </p>
      <p className="title">
        <strong>Les droits de l’utilisateur</strong>
      </p>
      <p>
        Conformément à la loi du 6 janvier 1978, tout utilisateur possède un
        droit d’accès et d’interrogation du fichier des données le concernant,
        ainsi qu’un droit à la rectification de ces données.
      </p>
      <p>
        Chaque utilisateur possède le droit d’opposition au traitement de ses
        données personnelles et d’opposition aux fins d’utilisations
        commerciales et de prospection.
      </p>
      <p>
        Le droit à la rectification, à la mise à jour, à la suppression, au
        blocage en cas d’inexactitude des données, lorsqu’elles sont
        incomplètes, périmées ou en cas d’interdiction de l’utilisation de ces
        données par un traitement, une collecte, leur communication.
      </p>
      <p>
        Toute requête visant à exercer un droit de rectification devra être
        adressée par courrier électronique à {siteName} et accompagnée d’une
        copie d’un titre d’identité signé.
      </p>
      <p className="title">
        <strong>Cookies et Traceurs</strong>
      </p>
      <p>
        {siteName} utilise des cookies. Notre politique en matière de cookies
        est consultable sur la page CGV et Mentions Légales.
      </p>
    </div>
  )
}

export default PrivacyStatement
