import { HostType, SourceType } from "../../context/comparator/types"

import { ReactComponent as ReservappLogo } from "../../assets/images/reservapp.svg"
import { ReactComponent as Reserv4meLogo } from "../../assets/images/reserv4me.svg"
import { ReactComponent as ReservUpLogo } from "../../assets/images/reservup.svg"
import { ReactComponent as ServimateLogo } from "../../assets/images/servimate.svg"

const LogoIcon = ({ color = "#EFF3FA", secondColor = "#5F90F7", ...rest }) => {
  if ((localStorage.getItem("source") as SourceType).includes("-easy")) {
    color = "#023047"
    secondColor = "#219EBC"
  }

  const host = localStorage.getItem("host") as HostType

  return host === "orga-call" ? (
    <svg
      width="72"
      height="90"
      viewBox="0 0 72 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_618_5217)">
        <path
          d="M71.9989 18.3431L60.8689 25.2303C58.9484 22.1683 56.4035 19.5432 53.3994 17.5256C50.3954 15.508 46.9993 14.1431 43.4317 13.5193C39.8642 12.8956 36.205 13.027 32.6917 13.9051C29.1784 14.7831 25.8896 16.3882 23.0389 18.6161C20.1882 20.8439 17.8393 23.6448 16.1447 26.8367C14.4501 30.0286 13.4478 33.5402 13.2028 37.1435C12.9578 40.7468 13.4756 44.3612 14.7226 47.752C15.9695 51.1427 17.9178 54.2341 20.4409 56.8252V72.9977C15.1922 70.1724 10.6649 66.182 7.20865 61.3348C3.75236 56.4875 1.45952 50.9131 0.507162 45.0418C-0.445193 39.1706 -0.0315824 33.1597 1.71606 27.4732C3.4637 21.7866 6.49861 16.5767 10.5865 12.2456C14.6743 7.91446 19.7057 4.57806 25.2922 2.49399C30.8787 0.409925 36.8708 -0.366055 42.8059 0.22597C48.7409 0.817995 54.4601 2.76219 59.5219 5.90842C64.5836 9.05466 68.8525 13.3188 71.9989 18.3715V18.3431Z"
          fill={color}
        />
        <path
          d="M26.4414 89.9997V59.0015C26.4389 56.7441 27.0415 54.5271 28.1867 52.5799C29.332 50.6327 30.9783 49.026 32.9553 47.9262C34.7194 49.2226 36.8601 49.9087 39.0514 49.8799C41.2427 49.8512 43.3646 49.1093 45.0939 47.7671C47.1442 48.8466 48.8607 50.463 50.0586 52.4425C51.2565 54.4219 51.8906 56.6895 51.8927 59.0015V61.0756C55.5489 58.9505 58.6298 55.968 60.8685 52.387L71.9928 59.2685C68.5089 64.8825 63.6431 69.5149 57.8578 72.7255C52.0726 75.936 45.56 77.6181 38.9391 77.6118L26.4585 89.9997"
          fill={secondColor}
        />
        <path
          d="M46.9939 40.1148C47.7176 35.6694 44.6902 31.4807 40.232 30.7592C35.7738 30.0376 31.573 33.0563 30.8494 37.5017C30.1257 41.9471 33.1531 46.1357 37.6113 46.8573C42.0695 47.5789 46.2703 44.5602 46.9939 40.1148Z"
          fill={secondColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_618_5217">
          <rect width="72" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : host === "reservapp" ? (
    <ReservappLogo {...rest} />
  ) : host === "reservup" ? (
    <ReservUpLogo {...rest} />
  ) : host === "servimate" ? (
    <ServimateLogo {...rest} />
  ) : host === "reserv4me" ? (
    <Reserv4meLogo {...rest} />
  ) : null
}

export default LogoIcon
