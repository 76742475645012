import { FC, useContext, useEffect, useMemo, useState } from "react"
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import Spinner from "../subComponents/Spinner"
import { Lock } from "react-feather"
import { useNavigate } from "react-router-dom"
import { PaymentContext, PaymentState } from "../../context/paymentContext"
import { SlackService } from "../../services/slackService"
import { ComparatorContext } from "../../context/comparator/context"
import CardNumberElementInput from "./CardNumberElementInput"
import CardExpiryElementInput from "./CardExpiryElementInput"
import CardCvcElementInput from "./CardCvcElementInput"
import { GetSecretPayload, stripeService } from "../../services/stripeService"
import {
  getAmplitudeInstance,
  sendAmplitudeTrack,
} from "../../services/amplitude"
import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/globalContext"
import Button from "../button/Button"
import Modal from "../modals/Modal"
import { parsePhoneNumber } from "libphonenumber-js/min"
import { getSubscriptionPrice } from "../../utils"
import {
  HostType,
  SourceType,
  SourceTypeEnum,
} from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"

interface StripeFieldsProps {
  formIsCompleted: boolean
  verifyFormValues: () => boolean
  paymentState: PaymentState
  withoutLegals?: boolean
  hasBearer: boolean | null
}

const StripeFields: FC<StripeFieldsProps> = ({
  formIsCompleted,
  verifyFormValues,
  withoutLegals = false,
  hasBearer,
}) => {
  // ABTest: uncomment to active
  const abVersion: "A" | "B" =
    (localStorage.getItem("version") as "A" | "B") ?? "A"

  const { siteName } =
    globalHostConfig[localStorage.getItem("host") as HostType]

  const { comparatorState } = useContext(ComparatorContext)
  const { paymentState, setPaymentState } = useContext(PaymentContext)
  const { isMobile } = useContext(GlobalContext)

  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [globalError, setGlobalError] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [displayFixed, setDisplayFixed] = useState(false)
  const [cardNumberIsValid, setCardNumberIsValid] = useState<0 | 1 | -1>(0)
  const [cardExpiryIsValid, setCardExpiryIsValid] = useState<0 | 1 | -1>(0)
  const [cardCvcIsValid, setCardCvcIsValid] = useState<0 | 1 | -1>(0)
  const [legalChecked, setLegalChecked] = useState<boolean>(false)
  const [openForeignNumbersModal, setOpenForeignNumbersModal] =
    useState<boolean>(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const { deviceId, userId } = getAmplitudeInstance()

    if (deviceId || userId) {
      sendAmplitudeTrack("Click - Payment page")
    }

    if (verifyFormValues()) {
      setIsLoading(true)

      const parsedNumber = parsePhoneNumber(paymentState.customer.phone)
      if (parsedNumber.countryCallingCode !== "33") {
        setOpenForeignNumbersModal(true)
        setIsLoading(false)
      } else {
        try {
          const cardNumber = elements?.getElement(CardNumberElement)

          if (cardNumber) {
            let customerPayload: any = {}
            if (hasBearer === false) {
              customerPayload = {
                ...paymentState.bearer,
              }
            } else {
              customerPayload = { ...paymentState.customer }
            }
            const payload: GetSecretPayload = {
              source: comparatorState?.source || "",
              customer: {
                ...customerPayload,
              },
              trip_infos: {
                roundtrip:
                  comparatorState?.departureDate && comparatorState?.returnDate
                    ? true
                    : false,
                departure_time: comparatorState?.departureDate,
                roundtrip_time: comparatorState?.returnDate || "",
                origin: comparatorState?.from?.name,
                destination: comparatorState?.to?.name,
                nb_ticket: comparatorState?.passengers,
                price: comparatorState?.price,
              },
            }

            const response = await stripeService.getSecret(payload)
            const { clientSecret, customer } = await response.json()

            const responseCardSetup = await stripe?.confirmCardSetup(
              clientSecret,
              {
                payment_method: {
                  card: cardNumber,
                  billing_details: {
                    name: `${customer.firstname} ${customer.lastname}`,
                  },
                },
              }
            )

            setIsLoading(false)
            if (responseCardSetup?.error) {
              setPaymentState({
                ...paymentState,
                error: responseCardSetup.error.message,
              })
            } else {
              setPaymentState({
                ...paymentState,
                error: null,
              })
              await SlackService.sendPaymentConfirm({
                source: comparatorState?.source || "train",
                source_url: comparatorState?.source_url || "",
                lastname: paymentState.customer.lastname,
                firstname: paymentState.customer.firstname,
                phone: paymentState.customer.phone,
                email: paymentState.customer.email,
                roundtrip:
                  comparatorState?.departureDate && comparatorState?.returnDate
                    ? true
                    : false,
                departure: comparatorState?.from.name || "",
                arrival: comparatorState?.to.name || "",
                departureDate: comparatorState?.departureDate || "",
                returnDate: comparatorState?.returnDate || "",
                passengers: comparatorState?.passengers || 1,
                price: comparatorState?.price || 0,
                ...(!hasBearer && paymentState.bearer
                  ? {
                      lastnameCard: paymentState.bearer.lastname,
                      firstnameCard: paymentState.bearer.firstname,
                      phoneCard: paymentState.bearer.phone,
                      emailCard: paymentState.bearer.email,
                    }
                  : {}),
              })

              const searchParams = new URLSearchParams()

              if (userId) {
                searchParams.append("auid", userId)
              }
              if (deviceId) {
                searchParams.append("adid", deviceId)
              }

              const sourceWebsite = localStorage.getItem("source") as SourceType

              let redirectionUrl: URL | null = null

              switch (sourceWebsite) {
                case SourceTypeEnum.Train:
                case SourceTypeEnum.Bus:
                case SourceTypeEnum.Avion:
                  redirectionUrl = new URL(
                    `${globalConfig[sourceWebsite].redirectUrl}/confirmation`
                  )
                  break
                case SourceTypeEnum.TrainEasy:
                case SourceTypeEnum.BusEasy:
                case SourceTypeEnum.AvionEasy:
                  // case SourceTypeEnum.TrainGo:
                  // case SourceTypeEnum.BusGo:
                  // case SourceTypeEnum.AvionGo:
                  navigate({
                    pathname: "/confirm",
                    search: searchParams.toString(),
                  })
                  break
              }

              if (redirectionUrl) {
                // Create amp search params
                const redirectSearchParams = new URLSearchParams()
                if (userId) {
                  redirectSearchParams.set("auid", userId)
                }
                if (deviceId) {
                  redirectSearchParams.set("adid", deviceId)
                }

                const dataTravel = {
                  source: comparatorState?.source || "train",
                  source_url: comparatorState?.source_url || "",
                  lastname: paymentState.customer.lastname,
                  firstname: paymentState.customer.firstname,
                  phone: paymentState.customer.phone,
                  email: paymentState.customer.email,
                  roundtrip:
                    comparatorState?.departureDate &&
                    comparatorState?.returnDate
                      ? true
                      : false,
                  departure: comparatorState?.from?.name || "",
                  arrival: comparatorState?.to?.name || "",
                  departureDate: comparatorState?.departureDate || "",
                  returnDate: comparatorState?.returnDate || "",
                  passengers: comparatorState?.passengers || 1,
                  price: comparatorState?.price || 0,
                }

                // Create futur redirection url
                const urlFrom = new URL(window.location.origin + "/confirm")
                urlFrom.search = redirectSearchParams.toString()

                // Create current redirection url
                redirectSearchParams.set(
                  "urlFrom",
                  encodeURIComponent(urlFrom.href)
                )

                // Create dataTravel search params
                redirectSearchParams.set(
                  "dataTravel",
                  encodeURIComponent(JSON.stringify(dataTravel))
                )
                redirectionUrl.search = redirectSearchParams.toString()

                window.location.href = redirectionUrl.toString()
              }
            }
          } else {
            throw new Error("Card number is not valid")
          }
          setGlobalError(null)
        } catch (e: any) {
          setGlobalError(
            "Une erreur est survenue. Veuillez vérifier vos informations. N'hésitez pas à nous contacter si le problème persiste"
          )
          setIsLoading(false)
        }
      }
    }
  }

  const completed = useMemo(() => {
    return (
      cardNumberIsValid === 1 &&
      cardExpiryIsValid === 1 &&
      cardCvcIsValid === 1 &&
      formIsCompleted
    )
  }, [formIsCompleted, cardCvcIsValid, cardExpiryIsValid, cardNumberIsValid])

  useEffect(() => {
    const handleScroll = () => {
      const submit = document.getElementById("stripe-submit")
      const footer = document.getElementById("footer")
      const submitFixed = document.getElementById("stripe-submit-fixed")

      if (submit && footer && submitFixed) {
        const footerPosition = footer.getBoundingClientRect()
        const submitFixedPosition = submitFixed.getBoundingClientRect()
        const submitPosition = submit.getBoundingClientRect()

        if (submitPosition.top < 0 - submit.offsetHeight) {
          if (submitFixedPosition.bottom < footerPosition.top) {
            setDisplayFixed(true)
          } else {
            setDisplayFixed(false)
          }
        } else {
          setDisplayFixed(false)
        }
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (openForeignNumbersModal) {
      const parsedNumber = parsePhoneNumber(paymentState.customer.phone)
      sendAmplitudeTrack("View - Blocking popup - Post payment", {
        isDifferentFromTraveler: !hasBearer,
        foreignPhoneNumber: parsedNumber.countryCallingCode !== "33",
      })
    }
  }, [openForeignNumbersModal])

  return (
    <div className="stripe-fields">
      <div className="bank">
        <Lock size={16} color="#000" />
        <img src="/images/Logos CB.png" alt="logo-CB" />
      </div>

      <CardNumberElementInput
        cardNumberIsValid={cardNumberIsValid}
        setCardNumberIsValid={setCardNumberIsValid}
      />

      <div className="row">
        <CardExpiryElementInput
          cardExpiryIsValid={cardExpiryIsValid}
          setCardExpiryIsValid={setCardExpiryIsValid}
        />
        <CardCvcElementInput
          cardCvcIsValid={cardCvcIsValid}
          setCardCvcIsValid={setCardCvcIsValid}
        />
      </div>

      {!withoutLegals && (
        <div className="form-input checkbox">
          {/* <input
          required
          type="checkbox"
          name="confidentiality"
          id="confidentiality"
          onChange={(e) => setConfidentialityChecked(e.target.checked)}
        /> */}
          <label className="mobile-view" htmlFor="confidentiality">
            <small className="dark justify legals">
              En cliquant sur « valider et continuer » j’accepte la{" "}
              <Link to="/privacy-statement" target="_blank">
                charte de confidentialité
              </Link>
              , l’offre {siteName} : bénéficiez de 3 jours d'essai gratuit pour
              tester les services de la conciergerie, puis{" "}
              {getSubscriptionPrice()}eur par mois, annulable à tout moment.
              Offre garantie satisfait ou remboursé. De plus, j'accepte les{" "}
              <Link to="/terms-of-use-&-legal-notices" target="_blank">
                conditions générales
              </Link>{" "}
              de ventes associées.
            </small>
          </label>
          <label className="desktop-view" htmlFor="confidentiality">
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "12px",
                gap: "12px",
              }}
            >
              <input
                type="checkbox"
                name="confidentiality"
                id="confidentiality"
                onChange={(e) => setLegalChecked(e.target.checked)}
                checked={legalChecked}
              />
              <small
                className="dark justify"
                style={{
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
              >
                J’accepte la{" "}
                <Link
                  to="/privacy-statement"
                  target="_blank"
                  style={{
                    color: "rgba(33, 33, 33, 0.8)",
                    textDecoration: "underline",
                  }}
                >
                  charte de confidentialité
                </Link>
                , l’offre d’abonnement {siteName} (3 jours gratuits puis{" "}
                {getSubscriptionPrice()} eur par mois, annulable à tout moment)
                et les{" "}
                <Link
                  to="/terms-of-use-&-legal-notices"
                  target="_blank"
                  style={{
                    color: "rgba(33, 33, 33, 0.8)",
                    textDecoration: "underline",
                  }}
                >
                  conditions générales
                </Link>{" "}
                de vente associées
              </small>
            </div>
          </label>
        </div>
      )}

      <Button
        id="stripe-submit"
        type="submit"
        className={
          " bg-red btn red desktop-view" +
          (!(completed && legalChecked) ? " disabled" : "")
        }
        onClick={handleSubmit}
        disabled={!(completed && legalChecked)}
      >
        {!isLoading ? (
          <>
            <p>Continuer</p>
            <Lock size={24} color="#fff" />
          </>
        ) : (
          <Spinner />
        )}
      </Button>
      <Button
        id="stripe-submit"
        type="submit"
        className={
          "btn red bg-red mobile-view continue" +
          (!completed ? " disabled" : "")
        }
        onClick={handleSubmit}
        disabled={!completed}
        style={{ gap: "12px" }}
      >
        {!isLoading ? (
          <>
            <p>Valider et continuer</p>
            <Lock size={24} color="#fff" />
          </>
        ) : (
          <Spinner />
        )}
      </Button>
      <div
        id="stripe-submit-fixed"
        style={{
          visibility: displayFixed ? "visible" : "hidden",
        }}
      >
        <Button
          id="stripe-submit-fixed-button"
          className="bg-red btn red desktop-view"
          type="submit"
          onClick={handleSubmit}
          disabled={!legalChecked}
          style={{ gap: "12px" }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p className="light w600">Continuer</p>
              <Lock size={24} color="#fff" />
            </>
          )}
        </Button>
        <button
          id="stripe-submit-fixed-button"
          className="btn red mobile-view"
          type="submit"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p className="light w600">Continuer</p>
              <Lock size={24} color="#fff" />
            </>
          )}
        </button>
      </div>
      {globalError && (
        <div className="global-error-wrapper">
          <p>{globalError}</p>
        </div>
      )}

      <Modal
        open={openForeignNumbersModal}
        onClose={() => setOpenForeignNumbersModal(false)}
        className="foreign-modal-wrapper"
        closable={false}
      >
        <div className="foreign-modal-content">
          <div className="title">Le site est actuellement en maintenance</div>
          <div className="description">
            Nous nous excusons pour le désagrément, mais il n'est pas possible
            de s'abonner pour le moment.{" "}
            <strong>Votre paiement n'a pas abouti.</strong>
            <br />
            <br />
            Nous travaillons activement à améliorer notre site et à résoudre
            tous les problèmes techniques rencontrés. Nous vous recontacterons
            dès que possible, dès que vous pourrez à nouveau vous abonner.
          </div>

          <a
            className="return-btn"
            href={
              comparatorState?.source.includes("train")
                ? "https://www.sncf-connect.com/"
                : "https://www.flixbus.fr/"
            }
          >
            {comparatorState?.source.includes("train")
              ? "Continuer sur SNCF"
              : "Continuer sur Flixbus"}
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default StripeFields
