import { globalConfig } from "../config"
import { SourceType } from "../context/comparator/types"
import { sendAmplitudeTrack } from "./amplitude"

export interface SlackServiceData {
  source: SourceType
  source_url: string
  lastname: string
  firstname: string
  phone: string
  email: string
  lastnameCard?: string
  firstnameCard?: string
  phoneCard?: string
  emailCard?: string
  roundtrip: boolean
  departure: string
  arrival: string
  departureDate: string
  returnDate: string
  passengers: number
  price: number
}

export const SlackService = {
  formatData: (data: SlackServiceData) => {
    const text = ` 
- Source: ${globalConfig[data.source].name}
- Source url: ${data.source_url}
- Voyageur Principal:
    - Nom: ${data.lastname}
    - Prénom: ${data.firstname}
    - Tél: ${data.phone}
    - Email: ${data.email}
- Abonné:
    - Nom: ${data.lastnameCard || data.lastname}
    - Prénom: ${data.firstnameCard || data.firstname}
    - Tél: ${data.phoneCard || data.phone}
    - Email: ${data.emailCard || data.email}
- Aller-Retour: ${data.roundtrip ? "Oui" : "Non"}
- Gare de départ: ${data.departure}
- Gare d’arrivée: ${data.arrival}
- Date aller: ${Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(new Date(data.departureDate))}${
      data.returnDate !== ""
        ? `
- Date retour: ${Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(data.returnDate))}`
        : ""
    }
- Nb de voyageurs: ${data.passengers}
- Prix: ${data.price}`

    return text
  },

  sendPaymentConfirm: async (data: SlackServiceData) => {
    const text = SlackService.formatData(data)

    let url = ""

    if (
      data.source.includes("train") &&
      process.env.REACT_APP_SLACK_CONFIRM_TRAIN &&
      process.env.REACT_APP_SLACK_CONFIRM_TRAIN !== ""
    ) {
      url = process.env.REACT_APP_SLACK_CONFIRM_TRAIN
    } else if (
      data.source.includes("bus") &&
      process.env.REACT_APP_SLACK_CONFIRM_BUS &&
      process.env.REACT_APP_SLACK_CONFIRM_BUS !== ""
    ) {
      url = process.env.REACT_APP_SLACK_CONFIRM_BUS
    } else if (
      data.source.includes("avion") &&
      process.env.REACT_APP_SLACK_CONFIRM_AVION &&
      process.env.REACT_APP_SLACK_CONFIRM_AVION !== ""
    ) {
      url = process.env.REACT_APP_SLACK_CONFIRM_AVION
    } else {
      url = globalConfig[data.source].slack.confirmUrl || ""
    }

    try {
      if (url === "") throw new Error("No slack url")

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ text }),
      })

      if (!response.ok) throw new Error("Slack response not ok")

      await sendAmplitudeTrack("Tag - Slack msg - ok", {
        payload: data,
        slackChannel: "confirm resa",
      }).promise
    } catch (error: any) {
      await sendAmplitudeTrack("Tag - Slack msg - error", {
        payload: data,
        slackChannel: "confirm resa",
        url,
        error: error.message ?? error,
      }).promise
    }
  },

  sendCallMeBack: async (data: SlackServiceData) => {
    const text = SlackService.formatData(data)

    const url = globalConfig[data.source].slack.callMeBackUrl || ""

    try {
      if (url === "") throw new Error("No 'call me back' slack url")

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ text }),
      })

      if (!response.ok) throw new Error("Slack response not ok")

      await sendAmplitudeTrack("Tag - Slack msg - ok", {
        payload: data,
        slackChannel: "call me back",
      }).promise
    } catch (error: any) {
      await sendAmplitudeTrack("Tag - Slack msg - error", {
        payload: data,
        slackChannel: "call me back",
        url,
        error: error.message ?? error,
      }).promise
    }
  },
}
