import { HostType, SourceType } from "../context/comparator/types"

export const setCssEasy = (source: SourceType, host: HostType) => {
  const root = document.documentElement
  const body = document.body

  if (source.includes("-easy")) {
    /* Colors */
    root.style.setProperty("--color-blue-dark", "#023047")
    root.style.setProperty("--color-blue", "#219EBC")
    root.style.setProperty("--color-blue-light", "#E9F5F8")

    root.style.setProperty("--color-gray", "#E6EAED")

    root.style.setProperty("--color-red-dark", "")
    root.style.setProperty("--color-red", "")

    root.style.setProperty("--color-orange-dark", "#D46A00")
    root.style.setProperty("--color-orange", "#FB8500")

    /* Texts */
    root.style.setProperty("--color-text-input-label", "#023047")

    /* Buttons */

    /* error */
    root.style.setProperty("--color-bg-button-error", "#FB8500")
    root.style.setProperty("--color-border-button-error", "#FB8500")
    root.style.setProperty("--color-text-button-error", "#ffffff")

    root.style.setProperty("--color-bg-button-error-disabled", "#D46A00")
    root.style.setProperty("--color-border-button-error-disabled", "#D46A00")
    root.style.setProperty("--color-text-button-error-disabled", "#ffffff")

    /* light underline */
    root.style.setProperty("--color-bg-button-light-underline", "")
    root.style.setProperty("--color-border-button-light-underline", "")
    root.style.setProperty("--color-text-button-light-underline", "")

    root.style.setProperty("--color-bg-button-disabled", "")
    root.style.setProperty("--color-border-button-disabled", "")
    root.style.setProperty("--color-text-button-disabled", "")
  } else {
    switch (host) {
      case "orga-call":
        break
      case "reservapp":
        // purple orange
        // body.style.setProperty("--color-primary", "#18063a")
        // body.style.setProperty("--color-secondary", "#f6f0ff")
        // body.style.setProperty("--color-tertiary", "#5b46a8")

        // root.style.setProperty("--color-blue-dark", "#18063a")
        // root.style.setProperty("--color-blue", "#ff901a")
        // root.style.setProperty("--color-blue-light", "#f6f0ff")

        // root.style.setProperty("--color-bg-icon-light-blue", "#f3dffc")
        // root.style.setProperty("--color-bg-icon-light-blue2", "#edd1fa")

        // root.style.setProperty("--color-text-input-label", "#5b46a8")

        // root.style.setProperty("--color-bg-blue-light", "#f6f0ff")
        // root.style.setProperty("--color-bg-blue-lighter", "#fff4e5")

        // root.style.setProperty(
        //   "--color-bg-blue-alpha",
        //   "rgba(255, 144, 26, 0.1)"
        // )

        // root.style.setProperty("--color-title-foreign-modal", "#5b46a8")

        break
      case "reservup":
        // green yellow
        break
      // case "reserv4me":
      //   // red gray
      //   break
      case "servimate":
        // purple orange
        break
      default:
        break
      //  purle orange green yellow red gray
    }
  }
}

type keyProps = "icon" | "icon2"

export const getColor = (source: SourceType, host: HostType, key: keyProps) => {
  if (source.includes("-easy")) {
    switch (key) {
      case "icon":
        return "#219EBC"
      case "icon2":
        return "#023047"
    }
  } else {
    switch (host) {
      case "orga-call":
        switch (key) {
          case "icon":
            return "#1C50D0"
          case "icon2":
            // return "#06102A"
            return undefined
        }
      case "reservapp":
        switch (key) {
          case "icon":
            return "#1C50D0"
          // return "#ff901a"
          case "icon2":
            // return "#e76f24"
            return undefined
        }
    }
  }
}

// export const setCssGo = () => {
//   const root = document.documentElement

//   /* Colors */

//   root.style.setProperty("--color-blue-dark", "#023047")
//   root.style.setProperty("--color-blue", "#219EBC")
//   root.style.setProperty("--color-blue-light", "#E9F5F8")

//   root.style.setProperty("--color-gray", "#E6EAED")

//   root.style.setProperty("--color-red-dark", "")
//   root.style.setProperty("--color-red", "")

//   root.style.setProperty("--color-orange-dark", "#D46A00")
//   root.style.setProperty("--color-orange", "#FB8500")

//   /* Texts */
//   root.style.setProperty("--color-text-input-label", "#023047")

//   /* Buttons */

//   /* error */
//   root.style.setProperty("--color-bg-button-error", "#FB8500")
//   root.style.setProperty("--color-border-button-error", "#FB8500")
//   root.style.setProperty("--color-text-button-error", "#ffffff")

//   root.style.setProperty("--color-bg-button-error-disabled", "#D46A00")
//   root.style.setProperty("--color-border-button-error-disabled", "#D46A00")
//   root.style.setProperty("--color-text-button-error-disabled", "#ffffff")

//   /* light underline */
//   root.style.setProperty("--color-bg-button-light-underline", "")
//   root.style.setProperty("--color-border-button-light-underline", "")
//   root.style.setProperty("--color-text-button-light-underline", "")

//   root.style.setProperty("--color-bg-button-disabled", "")
//   root.style.setProperty("--color-border-button-disabled", "")
//   root.style.setProperty("--color-text-button-disabled", "")
// }
