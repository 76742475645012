import "./stripeForm.scss"
import StripeFields from "./StripeFields"
import { useCallback, useContext, useMemo, useRef, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { Stripe, loadStripe } from "@stripe/stripe-js"
import { PaymentContext, PaymentState } from "../../context/paymentContext"
import "react-phone-number-input/style.css"
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import { getSubscriptionPrice, isValidEmail } from "../../utils"
import { globalHostConfig } from "../../config"
import { HostType } from "../../context/comparator/types"

// const stripePromise: Promise<Stripe | null> = loadStripe(
//   globalHostConfig[localStorage.getItem("host") as HostType]?.stripeKey || ""
// )

const getStripePromise = async () => {
  return loadStripe(
    globalHostConfig[localStorage.getItem("host") as HostType]?.stripeKey || ""
  )
}
type FormErrors = {
  firstname?: string
  lastname?: string
  email?: string
  firstnameCard?: string
  lastnameCard?: string
  emailCard?: string
  phone?: string
  phoneCard?: string
  checkbox?: string
}

const StripeForm = () => {
  const { paymentState, setPaymentState } = useContext(PaymentContext)

  const [hasBearer, setHasBearer] = useState<boolean | null>(null)
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const bearerContentRef = useRef<HTMLDivElement>(null)

  const stripePromise = useMemo(() => {
    return getStripePromise()
  }, [])

  const formIsCompleted = useMemo(() => {
    return (
      paymentState.customer.firstname !== "" &&
      paymentState.customer.lastname !== "" &&
      paymentState.customer.email !== "" &&
      paymentState.customer.phone !== "" &&
      (hasBearer === true ||
        (hasBearer === false &&
          paymentState.bearer?.firstname !== "" &&
          paymentState.bearer?.lastname !== "" &&
          paymentState.bearer?.email !== "" &&
          paymentState.bearer?.phone !== ""))
    )
  }, [paymentState, hasBearer])

  const verifyFormValues = useCallback(() => {
    const errors: FormErrors = {}

    if (paymentState.customer.firstname === "") {
      errors.firstname = "Le prénom est requis."
    }

    if (paymentState.customer.lastname === "") {
      errors.lastname = "Le nom est requis."
    }

    if (paymentState.customer.email === "") {
      errors.email = "L'email est requis."
    } else if (!isValidEmail(paymentState.customer.email)) {
      errors.email = "L'email n'est pas valide."
    }

    if (paymentState.customer.phone === "") {
      errors.phone = "Le téléphone est requis."
    } else if (!isValidPhoneNumber(paymentState.customer.phone)) {
      errors.phone = "Le numéro de téléphone n'est pas valide."
    }

    if (hasBearer === null) {
      errors.checkbox = "Veuillez sélectionner une option."
    } else if (hasBearer === false) {
      if (paymentState.bearer === undefined) {
        errors.firstnameCard = "Le prénom est requis."
        errors.lastnameCard = "Le nom est requis."
        errors.emailCard = "L'email est requis."
        errors.phoneCard = "Le téléphone est requis."
      } else {
        if (
          !paymentState.bearer.firstname ||
          paymentState.bearer.firstname === ""
        ) {
          errors.firstnameCard = "Le prénom est requis."
        }
        if (
          !paymentState.bearer.lastname ||
          paymentState.bearer?.lastname === ""
        ) {
          errors.lastnameCard = "Le nom est requis."
        }
        if (!paymentState.bearer.email || paymentState.bearer.email === "") {
          errors.emailCard = "L'email est requis."
        } else if (
          paymentState.bearer.email &&
          !isValidEmail(paymentState.bearer.email)
        ) {
          errors.emailCard = "L'email n'est pas valide."
        }

        if (!paymentState.bearer.phone || paymentState.bearer.phone === "") {
          errors.phoneCard = "Le téléphone est requis."
        } else if (
          paymentState.bearer.phone &&
          !isValidPhoneNumber(paymentState.bearer.phone)
        ) {
          errors.phoneCard = "Le numéro de téléphone n'est pas valide."
        }
      }
    }

    setFormErrors(errors)

    return Object.keys(errors).length === 0
  }, [paymentState, hasBearer])

  const handleChange = (e: any) => {
    const targetName = e.target.name
    if (targetName.includes("Card")) {
      if (targetName.includes("firstname")) {
        setPaymentState({
          ...paymentState,
          bearer: { ...paymentState.bearer, firstname: e.target.value },
        })
      } else if (targetName.includes("lastname")) {
        setPaymentState({
          ...paymentState,
          bearer: { ...paymentState.bearer, lastname: e.target.value },
        })
      } else if (targetName.includes("email")) {
        setPaymentState({
          ...paymentState,
          bearer: { ...paymentState.bearer, email: e.target.value },
        })
      }
    } else {
      setPaymentState({
        ...paymentState,
        customer: { ...paymentState.customer, [targetName]: e.target.value },
      })
    }
  }

  const fieldIsValid = (field: string, value: string): boolean => {
    if (field.includes("firstname") || field.includes("lastname")) {
      return value.length > 1
    }
    if (field.includes("email")) {
      return isValidEmail(value)
    }
    if (field === "checkbox") {
      return value === "on"
    }

    return false
  }

  return (
    <div id="form-container">
      <h3 className="dark desktop-view">Validez vos coordonnées</h3>
      <div className="desktop-view blue-disclaimer">
        <p className="title">3 jours d'essai gratuit</p>
        <p className="text">
          Profitez de la période d’essai de 3 jours gratuits puis abonnement de{" "}
          {getSubscriptionPrice()} euros par mois annulable à tout moment.
          Garantie satisfait ou remboursé.
        </p>
      </div>
      <form onSubmit={() => {}} action="">
        <h5 style={{ marginTop: "10px" }}>
          Informations du voyageur principal
        </h5>
        <div
          className="form-group"
          style={{
            border:
              fieldIsValid("firstname", paymentState.customer.firstname) &&
              fieldIsValid("lastname", paymentState.customer.lastname)
                ? "1px solid #02bd02"
                : "1px solid #fff",
          }}
        >
          <div className="form-input dual">
            <label htmlFor="firstname">Prénom</label>
            <input
              required
              value={paymentState.customer.firstname || ""}
              type="text"
              name="firstname"
              id="firstname"
              onChange={handleChange}
            />
            {formErrors.firstname && (
              <small className="error-message">{formErrors.firstname}</small>
            )}
          </div>
          <div className="form-input dual">
            <label htmlFor="lastname">Nom</label>
            <input
              required
              value={paymentState.customer.lastname || ""}
              type="text"
              name="lastname"
              id="lastname"
              onChange={handleChange}
            />
            {formErrors.lastname && (
              <small className="error-message">{formErrors.lastname}</small>
            )}
          </div>
        </div>
        <div
          className="form-group"
          style={{
            border: fieldIsValid("email", paymentState.customer.email)
              ? "1px solid #02bd02"
              : "1px solid #fff",
          }}
        >
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input
              required
              value={paymentState.customer.email || ""}
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
            />
            {formErrors.email && (
              <small className="error-message">{formErrors.email}</small>
            )}
          </div>
        </div>
        <div
          className="form-group"
          style={{
            border: isValidPhoneNumber(paymentState.customer.phone)
              ? "1px solid #02bd02"
              : "1px solid #fff",
          }}
        >
          <div className="form-input">
            <label htmlFor="phone">Téléphone</label>
            <PhoneInput
              international={false}
              value={paymentState.customer.phone || ""}
              onChange={(phone) => {
                if (phone === undefined) {
                  return
                }
                setPaymentState({
                  ...paymentState,
                  customer: {
                    ...paymentState.customer,
                    phone: formatPhoneNumberIntl(phone),
                  },
                })
              }}
              defaultCountry="FR"
              style={{
                flexDirection: "row-reverse",
              }}
              required
              numberInputProps={{
                name: "phone",
                id: "phone",
                required: true,
              }}
              limitMaxLength
            />

            {formErrors.phone && (
              <small className="error-message">{formErrors.phone}</small>
            )}
          </div>
        </div>
        <h5>Informations du porteur de la carte</h5>
        <div className="checkbox-bearer-wrapper">
          <div
            className="checkbox-other-person"
            onClick={() => setHasBearer(true)}
          >
            <input
              className="radio"
              type="radio"
              style={{
                flexShrink: 0,
              }}
              checked={hasBearer === true}
              onChange={() => setHasBearer(true)}
            />
            <label>Le voyageur principal est le porteur de la carte</label>
          </div>
          <div
            className="checkbox-other-person"
            onClick={() => setHasBearer(false)}
          >
            <input
              className="radio"
              type="radio"
              style={{
                flexShrink: 0,
              }}
              checked={hasBearer === false}
              onChange={() => setHasBearer(false)}
            />
            <label>
              Le voyageur principal n'est pas le porteur de la carte
            </label>
          </div>
          {formErrors.checkbox && (
            <small className="error-message">{formErrors.checkbox}</small>
          )}
        </div>
        <div
          ref={bearerContentRef}
          style={{
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
            maxHeight:
              hasBearer === false
                ? (bearerContentRef?.current?.scrollHeight || 200) + "px"
                : "0px",
          }}
        >
          <div
            className="form-group"
            style={{
              border:
                fieldIsValid(
                  "firstnameCard",
                  paymentState.bearer?.firstname || ""
                ) &&
                fieldIsValid(
                  "lastnameCard",
                  paymentState.bearer?.lastname || ""
                )
                  ? "1px solid #02bd02"
                  : "1px solid #fff",
            }}
          >
            <div className="form-input dual">
              <label htmlFor="firstnameCard">Prénom</label>
              <input
                required
                value={paymentState.bearer?.firstname || ""}
                type="text"
                name="firstnameCard"
                id="firstnameCard"
                onChange={handleChange}
              />
              {formErrors.firstnameCard && (
                <small className="error-message">
                  {formErrors.firstnameCard}
                </small>
              )}
            </div>
            <div className="form-input dual">
              <label htmlFor="lastnameCard">Nom</label>
              <input
                required
                value={paymentState.bearer?.lastname || ""}
                type="text"
                name="lastnameCard"
                id="lastnameCard"
                onChange={handleChange}
              />
              {formErrors.lastnameCard && (
                <small className="error-message">
                  {formErrors.lastnameCard}
                </small>
              )}
            </div>
          </div>
          <div
            className="form-group"
            style={{
              marginTop: "16px",
              border: fieldIsValid(
                "emailCard",
                paymentState.bearer?.email || ""
              )
                ? "1px solid #02bd02"
                : "1px solid #fff",
            }}
          >
            <div className="form-input">
              <label htmlFor="emailCard">Email</label>
              <input
                required
                value={paymentState.bearer?.email || ""}
                type="emailCard"
                name="emailCard"
                id="emailCard"
                onChange={handleChange}
              />
              {formErrors.emailCard && (
                <small className="error-message">{formErrors.emailCard}</small>
              )}
            </div>
          </div>
          <div
            className="form-group"
            style={{
              marginTop: "16px",
              border: isValidPhoneNumber(paymentState.bearer?.phone || "")
                ? "1px solid #02bd02"
                : "1px solid #fff",
            }}
          >
            <div className="form-input">
              <label htmlFor="phoneCard">Téléphone</label>
              <PhoneInput
                international={false}
                value={paymentState.bearer?.phone || ""}
                onChange={(phone) => {
                  if (phone === undefined) {
                    return
                  }
                  setPaymentState({
                    ...paymentState,
                    bearer: {
                      ...paymentState.bearer,
                      phone: formatPhoneNumberIntl(phone),
                    },
                  })
                }}
                defaultCountry="FR"
                style={{
                  flexDirection: "row-reverse",
                }}
                required
                numberInputProps={{
                  name: "phoneCard",
                  id: "phoneCard",
                  required: true,
                }}
                limitMaxLength
              />

              {formErrors.phoneCard && (
                <small className="error-message">{formErrors.phoneCard}</small>
              )}
            </div>
          </div>
        </div>
        <Elements stripe={stripePromise}>
          <StripeFields
            formIsCompleted={formIsCompleted}
            verifyFormValues={verifyFormValues}
            paymentState={paymentState}
            hasBearer={hasBearer}
          />
        </Elements>
      </form>
    </div>
  )
}

export default StripeForm
