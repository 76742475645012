import "./confirm.scss"
import { useContext, useEffect, useState } from "react"
import { PaymentContext } from "../../context/paymentContext"
import { SlackService, SlackServiceData } from "../../services/slackService"
import { ComparatorContext } from "../../context/comparator/context"
import AssistantButtons from "../subComponents/AssistantButtons"
import OfferInfoList from "../subComponents/OfferInfoList"
import DoubleArrowDown from "../icons/DoubleArrowDown"
import { ReactComponent as CallCenterIcon } from "../../assets/images/call-center.svg"
import { ReactComponent as SncfLogo } from "../../assets/images/sncf.svg"
import { ReactComponent as OmioLogo } from "../../assets/images/omio.svg"
import { ReactComponent as TrainlineLogo } from "../../assets/images/trainline.svg"
import { ReactComponent as SncfLogoDesktop } from "../../assets/images/sncf-desktop.svg"
import { ReactComponent as OmioLogoDesktop } from "../../assets/images/omio-desktop.svg"
import { ReactComponent as TrainlineLogoDesktop } from "../../assets/images/trainline-desktop.svg"
import { initAmplitude, sendAmplitudeTrack } from "../../services/amplitude"
import { GlobalContext } from "../../context/globalContext"
import InfoIcon from "../icons/InfoIcon"
import ArrowUpRight from "../icons/ArrowUpRight"
import { HostType, SourceType } from "../../context/comparator/types"
import { globalConfig, globalHostConfig } from "../../config"
import { getColor } from "../../services/css"

const Confirm = () => {
  const { paymentState } = useContext(PaymentContext)
  const { comparatorState } = useContext(ComparatorContext)
  const { isMobile } = useContext(GlobalContext)
  const [slackData, setSlackData] = useState<SlackServiceData | null>(null)

  const host = localStorage.getItem("host") as HostType

  const { siteName, webSite, faqPhone, contactPhone } = globalHostConfig[host]

  const abVersion: "A" | "B" =
    (localStorage.getItem("version") as "A" | "B") || "A"

  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const postPaymentDescription = `Inscription confirmée : cette transaction apparaîtra sur votre relevé bancaire sous l’intitulé “${host}.com - ${source}”`

  const callMeBack = async () => {
    if (!slackData) {
      return
    }
    await SlackService.sendCallMeBack(slackData)
  }

  const getAmplitudeOptions = () => {
    if (window.innerWidth < 1024) {
      return {
        abTestName: "confirmPage",
        abVersion,
      }
    }

    return {}
  }

  const sendCallEvent = () => {
    sendAmplitudeTrack("Click - Confirm page - Call", getAmplitudeOptions())
  }

  const sendCallMeBackEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Call me back",
      getAmplitudeOptions()
    )
  }

  const sendWhatsAppEvent = () => {
    sendAmplitudeTrack("Click - Confirm page - WhatsApp", getAmplitudeOptions())
  }

  const sendVisitOrgaCallEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Visit website link",
      getAmplitudeOptions()
    )
  }

  const sendWithoutAssistantEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - No assistance",
      getAmplitudeOptions()
    )
  }

  const sendUnsubscribeClickEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Unsubscribe link",
      getAmplitudeOptions()
    )
  }

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const adid = currentSearchParams.get("adid") ?? undefined
    const auid = currentSearchParams.get("auid") ?? undefined

    const source = localStorage.getItem("source") as SourceType

    const dataTravel = JSON.parse(
      decodeURIComponent(currentSearchParams.get("dataTravel") as string)
    )

    if (dataTravel) {
      setSlackData(dataTravel)
    } else {
      setSlackData({
        source: comparatorState?.source || "train",
        source_url: comparatorState?.source_url || "",
        lastname: paymentState.customer.lastname,
        firstname: paymentState.customer.firstname,
        phone: paymentState.customer.phone,
        email: paymentState.customer.email,
        roundtrip:
          comparatorState?.departureDate && comparatorState?.returnDate
            ? true
            : false,
        departure: comparatorState?.from?.name || "",
        arrival: comparatorState?.to?.name || "",
        departureDate: comparatorState?.departureDate || "",
        returnDate: comparatorState?.returnDate || "",
        passengers: comparatorState?.passengers || 1,
        price: comparatorState?.price || 0,
      })
    }

    const sendTrack = async () => {
      await initAmplitude(source, {
        userId: auid,
        deviceId: adid,
      }).promise

      sendAmplitudeTrack("View - Confirm page", getAmplitudeOptions())
    }

    sendTrack()
  }, [])

  return (
    <div id="confirm-page" className={specificClassName}>
      <div className="post-payment-description">
        <InfoIcon color={getColor(source, host, "icon2")} />
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: postPaymentDescription }}
        />
      </div>
      <div
        className="first-menu"
        onClick={(event) => {
          event.stopPropagation() // Empêche la propagation de l'événement
        }}
      >
        <div className="red-header" style={{ paddingTop: "20px" }}>
          <h2>Billet non réservé !</h2>
          <h4>Finalisez votre réservation ci-dessous</h4>
          <div className="icon">
            <DoubleArrowDown />
          </div>
        </div>

        <div className="menu-content">
          <div className="mobile-view">
            <div className="external-links-wrapper">
              <a
                href="https://www.sncf.com/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <SncfLogo />
                </div>
                <span className="label">SNCF</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.omio.fr/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <OmioLogo />
                </div>
                <span className="label">Omio</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.thetrainline.com/fr"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <TrainlineLogo />
                </div>
                <span className="label">Trainline</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
            </div>
          </div>
          <div className="left-side-menu-content">
            <h4 className={`assistant-title-wrapper`}>
              <div className="icon">
                <CallCenterIcon />
              </div>

              <p className="assistant-title">
                <span>Votre assistant</span>
                <span className="big">{siteName}</span>
              </p>
            </h4>

            <div>
              <AssistantButtons
                onClickAssistant={() => {
                  sendCallMeBackEvent()
                  callMeBack()
                }}
                hrefCall={`"tel:+33${
                  faqPhone ? faqPhone.slice(1) : contactPhone.slice(1)
                }"`}
                onClickCall={sendCallEvent}
                onClickWhatsApp={sendWhatsAppEvent}
                column={isMobile && abVersion === "B"}
              />
            </div>

            <div className={`md-auto-center`} style={{ marginTop: "4px" }}>
              <OfferInfoList />
            </div>
            <a
              className={`visit-goresa md-auto-center`}
              href={webSite}
              target="_blank"
              onClick={sendVisitOrgaCallEvent}
            >
              Visiter le site {siteName} {">"}
            </a>
          </div>
          <p className="assistant-or-text desktop-view">OU</p>
          <div className="desktop-view">
            <div className="external-links-wrapper">
              <a
                href="https://www.sncf.com/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <SncfLogoDesktop />
                </div>
                <span className="label">SNCF</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.omio.fr/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <OmioLogoDesktop />
                </div>
                <span className="label">Omio</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.thetrainline.com/fr"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <TrainlineLogoDesktop />
                </div>
                <span className="label">Trainline</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
            </div>
            <p className="small">
              Si vous ne faites pas appel à la conciergerie au delà des 3 jours
              gratuits, pensez à vous désabonner sans frais en{" "}
              <a
                href={webSite + "/abonnement"}
                target="_blank"
                onClick={sendUnsubscribeClickEvent}
              >
                cliquant ici
              </a>
              .
            </p>
          </div>
        </div>
        <p className="small mobile-view">
          Si vous ne faites pas appel à la conciergerie au delà des 3 jours
          gratuits, pensez à vous désabonner sans frais en{" "}
          <a
            href={webSite + "/abonnement"}
            target="_blank"
            onClick={sendUnsubscribeClickEvent}
          >
            cliquant ici
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default Confirm
